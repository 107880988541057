.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  /* background-color: #282c34; */
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

body {
  height: 100vh;
}

nav ul {
	flex: 1;
	text-align: right;
}
.hero {
	width: 100%;
	background: var(--primary-color);
	font-family: sans-serif;
	position: relative;
	transition: background 0.5s;
}
nav {
	width: 84%;
	margin: auto;
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.logo {
	width: 45px;
	cursor: pointer;
	border-radius: 50px;
}
nav ul li {
	list-style: none;
	display: inline-block;
	margin: 10px 20px;
}
nav ul li a {
	text-decoration: none;
	color: var(--secondary-color);
}

nav ul li a:hover {
	color: #ff4321;
}

.tails-item-box {
	height: 200px;
	max-height: 200px;
    overflow: auto;
}

.header-info {
	float: right;
    font-size: 12.5px;
    margin-top: 3.5px;
}
a {
	color: #212529;
	text-decoration: none;
}

.tbtn {
	background: red;
    padding: 2.5px;
    border-radius: 50px;
    font-size: 10px;
    color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
